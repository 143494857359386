import Vue from 'vue'
import Router from 'vue-router'
import adminRouter from '@/views/Admin/adminRouter'
import autenticacaoService from '@/services/autenticacao'
import store from '@/store/index'

const Dashboard = () => import('@/views/Home/Dashboard')
const Funcionario = () => import('@/views/Home/Funcionario')
const Atestados = () => import('@/views/Home/Atestados')
const Empresas = () => import('@/views/Home/Empresas')
const Indicadores = () => import('@/views/Home/Indicadores')
const LinksCadastro = () => import('@/views/Home/LinksCadastro')
const Login = () => import('./views/Autenticacao/Login')
const RedefinirSenha = () => import('./views/Public/RedefinirSenha')
const EnviarTokenEmailForm = () => import('@/views/Public/EnviarTokenEmailForm')
const RedefinirSenhaForm = () => import('@/views/Public/RedefinirSenhaForm')
const SessionExpired = () => import('./views/Autenticacao/SessionExpired')
const NotFound = () => import('./views/Autenticacao/NotFound')
const Home = () => import('./views/Home')
const Agendamento = () => import('@/views/Home/Agendamento')
const Resultado = () => import('@/views/Home/Resultado')
const CertificadoDigital = () => import('@/views/Home/CertificadoDigital')
const SegurancaTrabalho = () => import('@/views/Home/SegurancaTrabalho')
const ClinicaAgendamentos = () => import('@/views/Public/Clinica/ClinicaAgendamentos')
const ESocial = () => import('@/views/Home/ESocial')
const Beneficios = () => import('@/views/Home/Beneficios')
const ComprarEsocial = () => import('@/views/Home/ComprarEsocial')
const ComprarAmbulatorio = () => import('@/views/Home/ComprarAmbulatorio')
const Faturamento = () => import('@/views/Home/Faturamento')
const BemVindo = () => import('@/views/Home/BemVindo')
const SSOAuth = () => import('@/views/Home/SSOAuth')
const ContratoNovoNegocio = () => import('@/views/Home/ContratoNovoNegocio')
const AmbulatorioDigital = () => import('@/views/Public/AmbulatorioDigital')
const LandingAlimentacao = () => import('@/views/Public/LandingAlimentacao')
const LandingTerceirizacao = () => import('@/views/Public/LandingTerceirizacao')
const IndicacaoParceiroForm = () => import('@/views/Public/IndicacaoParceiroForm')
const Customizacao = () => import('@/views/Home/Customizacao')

Vue.use(Router)

const homeRouter = {
  path: '/',
  component: Home,
  children: [
    adminRouter,
    { path: 'dashboard', name: 'dashboard', component: Dashboard },
    { path: 'empresa', name: 'empresa', component: Empresas },
    { path: 'funcionario', name: 'funcionario', component: Funcionario },
    { path: 'atestados', name: 'atestados', component: Atestados },
    { path: 'indicadores', name: 'indicadores', component: Indicadores },
    { path: 'links-cadastro', name: 'links-cadastro', component: LinksCadastro },
    { path: 'agendamento', name: 'agendamento', component: Agendamento },
    { path: 'resultados', name: 'resultados', component: Resultado },
    { path: 'certificado-digital', name: 'certificado-digital', component: CertificadoDigital },
    { path: 'seguranca-trabalho', name: 'seguranca-trabalho', component: SegurancaTrabalho },
    { path: 'esocial', name: 'esocial', component: ESocial },
    { path: 'beneficios', name: 'beneficios', component: Beneficios },
    { path: 'comprar-esocial', name: 'comprar-esocial', component: ComprarEsocial },
    { path: 'comprar-ambulatorio', name: 'comprar-ambulatorio', component: ComprarAmbulatorio },
    { path: 'faturamento/:empresa', name: 'faturamento', component: Faturamento, props: true },
    { path: 'bem-vindo', name: 'bem-vindo', component: BemVindo },
    { path: 'customizacao', name: 'customizacao', component: Customizacao },
  ],
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    homeRouter,
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/recuperar-senha',
      component: RedefinirSenha,
      meta: { public: true },
      children: [
        {
          path: '',
          name: 'recuperarSenha',
          component: EnviarTokenEmailForm,
          meta: { public: true },
        },
        {
          path: 'redefinir-senha',
          name: 'redefinirSenha',
          component: RedefinirSenhaForm,
          meta: { public: true },
        },
      ],
    },
    {
      path: '/erro/sessaoExpirada',
      name: 'sessionExpired',
      component: SessionExpired,
      meta: { public: true },
    },
    {
      path: '/404',
      name: 'notFound',
      component: NotFound,
      meta: { public: true },
    },
    {
      path: '/clinica/:token/agendamentos',
      name: 'clinica',
      component: ClinicaAgendamentos,
      props: true,
      meta: { public: true },
    },
    {
      path: '/sso-auth/:token',
      name: 'sso',
      component: SSOAuth,
      meta: {
        public: true,
      },
    },
    {
      path: '/contrato/novo-negocio/:token',
      name: 'contrato-novo-negocio',
      component: ContratoNovoNegocio,
      meta: {
        public: true,
      },
    },
    {
      path: '/ambulatorio-digital',
      name: 'ambulatorio-digital',
      component: AmbulatorioDigital,
      meta: {
        public: true,
      },
    },
    {
      path: '/formulario-indicacao',
      name: 'formulario-indicacao',
      component: IndicacaoParceiroForm,
      meta: {
        public: true,
      },
    },
    {
      path: '/setor-alimentacao',
      name: 'setor-alimentacao',
      component: LandingAlimentacao,
      meta: {
        public: true,
      },
    },
    {
      path: '/setor-terceirizacao',
      name: 'setor-terceirizacao',
      component: LandingTerceirizacao,
      meta: {
        public: true,
      },
    },
  ],
})

function isPublicPage(to) {
  return to.matched.some(m => m.meta.public)
  // return (
  //   to.path === '/404' || to.path.includes('/erro/') || to.name === 'aso' || to.name === 'guia' || to.name === 'clinica'
  // )
}

function isPrivatePage(to) {
  return to.path.includes('/admin') || homeRouter.children.some(r => r.name === to.name)
}

function isLoginPage(to) {
  return to.name === 'login'
}

function isHomePage(to) {
  return to.path === '/'
}

function isFromAnyPrivatePage(from) {
  return isPrivatePage(from)
}

function pageAllowed(to) {
  const tipoUsuario = store.getters['autenticacao/tipoUsuario']
  const blockUsersCreation = store.getters['autenticacao/blockUsersCreation']

  if (tipoUsuario === 3) {
    return !to.path.includes('/admin')
  }
  if (blockUsersCreation || (tipoUsuario === 2 && to.path !== '/admin/user/create')) {
    return !to.path.includes('/admin')
  }

  return true
}

router.beforeEach(async (to, from, next) => {
  if (isPrivatePage(to)) {
    if (autenticacaoService.isAuthenticated() && pageAllowed(to)) {
      next()
    } else if (isFromAnyPrivatePage(from)) {
      next({ name: 'sessionExpired' })
    } else if (to.query.redirectTo) {
      next({ name: 'login', query: { redirectTo: to.query.redirectTo } })
    } else {
      next({ name: 'login' })
    }
  } else if (isPublicPage(to)) {
    next()
  } else if (isHomePage(to)) {
    if (!autenticacaoService.isAuthenticated()) {
      next({ name: 'login' })
    } else {
      next({ name: 'dashboard' })
    }
  } else if (isLoginPage(to)) {
    if (!autenticacaoService.isAuthenticated()) {
      next()
    } else {
      next({ name: 'dashboard' })
    }
  } else {
    // page notFound
    next({ name: 'notFound' })
  }
})

export default router
