import Axios from 'axios'

export default {
  listAdmin: async (page, perPage, filtros, options, mostrarFaturasZeradas) => {
    const filtroParams = {}

    filtros.forEach(filtro => (filtroParams[filtro.column] = filtro.value))

    let { sortDesc, sortBy } = options
    sortBy = sortBy[0] ? sortBy[0] : 'id'
    sortDesc = sortDesc[0] ? 'desc' : 'asc'

    const { itemsPerPage } = options
    perPage = itemsPerPage

    return Axios.get('/admin/faturas', {
      params: { page, perPage, sortBy, order: sortDesc, mostrarFaturasZeradas, ...filtroParams },
    })
  },

  searchEmpresa: async (empresaId, data) => {
    const { page, perPage } = data
    const params = { page, perPage }

    return Axios.get(`/empresas/${empresaId}/faturas`, { params })
  },
  invoiceCheck: async ids => {
    return Axios.patch('/admin/faturas/conferir', { ids })
  },
  invoiceUncheck: async ids => {
    return Axios.patch('/admin/faturas/cancelar-conferencia', { ids })
  },
  invoiceDetails: async id => {
    return Axios.get(`/faturas/invoice-details/${id}`)
  },
  invoiceInformation: async id => {
    return Axios.get(`/faturas/invoice-information/${id}`)
  },
  invoiceReportExcel: async id => {
    return Axios.get(`/faturas/invoice-report-excel/${id}`)
  },
  custoRedeReportExcel: async id => {
    return Axios.get(`/faturas/custo-rede/${id}`)
  },
  invoiceReportPDF: async id => {
    return Axios.get(`/faturas/invoice-report-pdf/${id}`)
  },
  removeInvoicePayment: async ids => {
    return Axios.put('/admin/faturas/remove-payment', { ids })
  },
  updateValorFatura: data => Axios.post('/faturas/update-valor-exame', data),
  processInvoice: id => Axios.post(`/admin/faturas/${id}/processar`),
  emitPayments: data => Axios.post('/admin/faturas/payment', data),
  storeOrUpdateLancamento: data => Axios.post('/admin/faturas/lancamento', data),
  deleteLancamento: idFaturaLancamento => Axios.delete(`/admin/faturas/lancamento/${idFaturaLancamento}`),
  emitPaymentGrouped: data => Axios.post('/admin/faturas/payment-grouped', data),
}
